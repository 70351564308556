import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { HomePage } from 'pages';

type Props = {};

const App: React.FC<Props> = () => {
  return (
    <Suspense fallback={<div className='loading-spinner-dot' />}>
      <Routes>
        <Route element={<HomePage />} path='/' />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Suspense>
  );
};

export default App;
